/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-param-reassign */
import React, { useCallback, useState } from 'react';

import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';
import * as dicomParser from 'dicom-parser';
import moment from 'moment-timezone';

import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { StyledDragAndDrop } from './styled/StyledDragAndDrop';
import { UploadStudyDetailsCardDicom } from '../../Study/UploadStudyDetailsCardDicom';
import { DicomStudyDetails } from '../../Study/styled/StyledStudyDetails';
import { Loader } from '../Loader/Loader';

const useStyles = makeStyles(() => ({
  wrapper: {
    maxWidth: 600,
    margin: '.5em auto 0',
    padding: '.5em',
    '@media (max-width:600px)': {
      width: 'calc(100% - 1em)',
      margin: '0 auto',
      padding: '0',
    },
  },
  info: {
    margin: '.5em',
    padding: '.25em',
    '@media (max-width:600px)': {
      marginTop: '1.25em',
    },
  },
}));

/**
 * This function checks if the id is in arr.
 * If it is not inside, it creates and push the obj into the arr. If the obj
 * exists, it is returned.
 * @param {Function} fnCreate
 * @param {array} arr
 * @param {string} id
 * @param {string} idName
 * @param {array} parsedFile
 */
const createAndAddTo = (fnCreate, arr, id, idName, parsedFile) => {
  let obj;
  if (arr.length === 0) {
    obj = fnCreate(id, parsedFile);
    arr.push(obj);
  } else {
    obj = arr.find((element) => element[idName] === id);
    if (obj === undefined) {
      obj = fnCreate(id, parsedFile);
      arr.push(obj);
    }
  }
  return obj;
};

const createPatient = (patientID, parsedFile) => {
  const patientName = parsedFile.string('x00100010');
  return {
    patientID,
    patientName,
    studies: [],
  };
};

const createStudy = (studyInstanceUid, parsedFile) => {
  const studyDescription = parsedFile.string('x00081030');
  return {
    studyInstanceUid,
    studyDescription,
    series: [],
    files: [],
    storageSize: 0,
  };
};

const createSeries = (seriesInstanceUid, parsedFile) => {
  const seriesDescription = parsedFile.string('x0008103e');
  const modality = parsedFile.string('x00080060');
  return {
    seriesInstanceUid,
    seriesDescription,
    modality,
    instances: [],
  };
};

const createInstances = (sopInstanceUid, parsedFile) => {
  const sopClassUid = parsedFile.string('x00080016');
  return {
    sopInstanceUid,
    sopClassUid,
    pathTofile: '',
  };
};

const fillPatientWithDicomData = (dicomPatients, parsedFile, arrayBuffer, pathTofile, fileSize) => {
  // ---------------- ADD PATIENT ------------------- //
  const patientID = parsedFile.string('x00100020');
  const patient = createAndAddTo(
    createPatient,
    dicomPatients.patients,
    patientID,
    'patientID',
    parsedFile,
  );

  // ---------------- ADD STUDY ------------------- //
  const studyInstanceUid = parsedFile.string('x0020000d');
  const studyDate = parsedFile.string('x00080020');
  const study = createAndAddTo(
    createStudy,
    patient.studies,
    studyInstanceUid,
    'studyInstanceUid',
    parsedFile,
  );
  study.patientID = patient.patientID;
  study.patientName = patient.patientName;
  study.createdAt = moment(studyDate).format('YYYY-MM-DD');
  study.files.push(arrayBuffer);
  study.storageSize += fileSize;

  // ---------------- ADD SERIES ------------------- //
  const seriesInstanceUid = parsedFile.string('x0020000e');
  const series = createAndAddTo(
    createSeries,
    study.series,
    seriesInstanceUid,
    'seriesInstanceUid',
    parsedFile,
  );

  // ---------------- ADD INSTANCES ------------------- //
  const sopInstanceUid = parsedFile.string('x00080018');
  const instance = createAndAddTo(
    createInstances,
    series.instances,
    sopInstanceUid,
    'sopInstanceUid',
    parsedFile,
  );
  instance.pathTofile = pathTofile;
};

const parseDicomFile = (arrayBuffer) => {
  const byteArray = new Uint8Array(arrayBuffer);
  return dicomParser.parseDicom(byteArray);
};

export const loadDicomPatients = (dicomPatients, file, fileContents) => {
  const parsedFile = parseDicomFile(fileContents);
  fillPatientWithDicomData(dicomPatients, parsedFile, fileContents, file.path, file.size);

  return dicomPatients.patients.forEach((patient) => {
    patient.studies.forEach((study) => {
      let totalInstances = 0;
      const modalities = new Set();
      study.series.forEach((serie) => {
        totalInstances += serie.instances.length;
        modalities.add(serie.modality);
      });
      study.modalities = [...modalities.values()].join(',');
      study.numberOfInstances = totalInstances;
    });
  });
};

export const Study = ({
  setDicomStudiesForParent,
  setFileUploadStateForParent,
  formProps,
  clearForm = false,
  setClearForm = () => {},
}) => {
  const { t } = useTranslation();
  const styles = useStyles();
  const [uploadingFiles, setUploadingFiles] = useState(false);
  const setUploadingState = (state) => {
    setUploadingFiles(state.status === 'UPLOADING');
    setFileUploadStateForParent(state, formProps);
  };
  const [dicomPatientFilled, setDicomPatientFilled] = useState(false);
  const [dicomPatientsState, setDicomPatientsState] = useState({});
  const dicomPatients = { patients: [] };

  const handleErrors = (type) => {
    let errorMessage;
    switch (type) {
      case 'FILE_READING_FAILED':
        errorMessage = 'study.upload.file.reading.failed';
        break;
      case 'FILE_READING_ABORTED':
        errorMessage = 'study.upload.file.reading.aborted';
        break;
      default:
        errorMessage = 'study.upload.error';
    }
    toast(t(errorMessage), { className: 'toast-error' });
  };

  const onDrop = useCallback((acceptedFiles) => {
    setUploadingState({ status: 'UPLOADING' });
    let numFilesLoaded = 0;
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onabort = () => handleErrors('FILE_READING_ABORTED');
      reader.onerror = () => handleErrors('FILE_READING_FAILED');
      reader.onload = () => {
        try {
          loadDicomPatients(dicomPatients, file, reader.result, formProps);
        } catch (ex) {
          setUploadingState({ status: 'ERROR', message: t('invalid.dicom.file') });
          return;
        }
        setDicomPatientsState({ ...dicomPatients });
        setDicomStudiesForParent({ ...dicomPatients });
        setDicomPatientFilled(true);
      };
      reader.onloadend = () => {
        numFilesLoaded += 1;
        if (numFilesLoaded === acceptedFiles.length) {
          setUploadingState({ status: 'UPLOADED' });
        }
      };
      reader.readAsArrayBuffer(file);
    });
  }, []);

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({ onDrop });

  const clearDropzone = () => {
    acceptedFiles.length = 0;
    acceptedFiles.splice(0, acceptedFiles.length);

    formProps.setFieldValue('uploadedStudy', '');
    setUploadingFiles(false);

    setDicomPatientFilled(false);
    setDicomPatientsState({});

    setTimeout(() => setClearForm(false), 200);
  };

  if (clearForm === true && dicomPatientFilled === true) {
    clearDropzone();
  }

  return (
    <>
      <Box className={styles.wrapper}>
        <StyledDragAndDrop {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          <span>
            <p>{t('drag.drop')}</p>
            <p>{t('or')}</p>
            <p><b>{t('click.choose.study')}</b></p>
          </span>
        </StyledDragAndDrop>
      </Box>
      {dicomPatientFilled && (
        <DicomStudyDetails classes={styles.info}>
          {dicomPatientsState.patients.map((patient) => (
            patient.studies && patient.studies.map((study, index) => (
              <Box key={index.toString()}>
                <UploadStudyDetailsCardDicom study={study} />
              </Box>
            ))
          ))}
          { uploadingFiles && <Loader isLoading={uploadingFiles} /> }
        </DicomStudyDetails>
      )}
    </>
  );
};
