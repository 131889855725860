import React from 'react';

import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import InfoAccordion from '../../componentsUI/InfoAccordion';
import { BytesPrettify } from '../../utils/BytesPrettifyUtils';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: '.75em 1.25em',
    '@media (max-width:600px)': {
    },
  },
  title: {
    fontSize: '1.125em',
    fontWeight: 800,
    marginBottom: 12,
  },
  attr: {
    padding: '0 .25em',
    '& p': {
      fontSize: '.9375em',
    },
  },
  key: {
    color: theme.palette.grey.A200,
  },
  val: {
    paddingLeft: 8,
    color: theme.palette.grey.A400,
  },
  series: {
    margin: '1em .25em',
    '& > p.series-head': {
      marginBottom: 4,
      fontWeight: 700,
      color: theme.palette.primary.main,
    },
    '& p.series-title': {
      fontWeight: 600,
    },
    '& .MuiAccordionSummary-root': {
      color: 'black',
      backgroundColor: theme.palette.primary.light,
      border: '1px solid',
      borderColor: theme.palette.primary.dark,
      '& .MuiIconButton-root': {
        color: theme.palette.primary.dark,
      },
    },
  },
  wrapper: {
    border: '1px #dfdfdf',
    borderStyle: 'none solid solid solid',
    padding: '.5em .75em',
    backgroundColor: '#f9f9f9',
    '&:nth-child(2)': {
      borderTop: '1px solid #dfdfdf',
    },
    '& p': {
      fontSize: '.875em',
    },
  },
}));

export const UploadStudyDetailsCardDicom = ({ study }) => {
  const { t } = useTranslation();
  const styles = useStyles();

  const StudySeries = () => (
    study.series.map((serie, index) => (
      <Box className={styles.wrapper} key={index.toString()}>
        <Typography className="series-title">{`${t('set')} #${index + 1}`}</Typography>
        <Box className={styles.attr}>
          <Typography display="inline" className={styles.key}>{`${t('images')}:`}</Typography>
          <Typography display="inline" className={styles.val}>{serie.instances.length}</Typography>
        </Box>
        <Box className={styles.attr}>
          <Typography display="inline" className={styles.key}>{`${t('modality')}:`}</Typography>
          <Typography display="inline" className={styles.val}>{serie.modality}</Typography>
        </Box>
        <Box className={styles.attr}>
          <Typography display="inline" className={styles.key}>{`${t('description')}:`}</Typography>
          <Typography display="inline" className={styles.val}>{serie.seriesDescription}</Typography>
        </Box>
      </Box>
    ))
  );

  const size = BytesPrettify(study.storageSize).string;

  return (
    <Box className={styles.card}>
      <Typography variant="body1" className={styles.title}>{study.studyDescription}</Typography>
      <Box className={styles.attr}>
        <Typography display="inline" className={styles.key}>{`${t('patient.name')}:`}</Typography>
        <Typography display="inline" className={styles.val}>{study.patientName}</Typography>
      </Box>
      <Box className={styles.attr}>
        <Typography display="inline" className={styles.key}>{`${t('series')}:`}</Typography>
        <Typography display="inline" className={styles.val}>{study.series.length}</Typography>
      </Box>
      <Box className={styles.attr}>
        <Typography display="inline" className={styles.key}>{`${t('images')}:`}</Typography>
        <Typography display="inline" className={styles.val}>{study.numberOfInstances}</Typography>
      </Box>
      <Box className={styles.attr}>
        <Typography display="inline" className={styles.key}>{`${t('modality')}:`}</Typography>
        <Typography display="inline" className={styles.val}>{study.modalities}</Typography>
      </Box>
      <Box className={styles.attr}>
        <Typography display="inline" className={styles.key}>{`${t('size')}:`}</Typography>
        <Typography display="inline" className={styles.val}>{size}</Typography>
      </Box>
      <Box className={styles.series}>
        <InfoAccordion title={`${t('Series')}: `} Item={StudySeries} />
      </Box>
    </Box>
  );
};
