import { Link } from '@reach/router';
import React, { forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Dialog, DialogActions, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';
import { FullViewHeader as Header } from '../Common/FullViewHeader';
import { InnerHTML } from '../Common/InnerHTML';

export const StudyUpload = {
  status: {
    notExisting: 1,
    existingNotAvailable: 2,
    existingAvailable: 3,
    existingAlready: 4,
  },
};

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const useStyles = makeStyles(() => ({
  dialog: {
    '& .MuiDialog-paper': {
      padding: '1em 2em',
      width: '100%',
      maxWidth: 600,
    },
    '& .MuiTypography-h6': {
      fontWeight: 600,
    },
  },
  confirmation: {
    marginTop: '1em',
  },
  actions: {
    marginTop: '1.5em',
  },
  button: {
    margin: '0 10px',
    padding: '8px 25px',
    fontWeight: 600,
  },
}));

export const UploadStudyConfirmationDialog = ({ upload, open, close }) => {
  const { t } = useTranslation();
  const styles = useStyles();
  const data = useSelector((state) => state && state.uploadingStudiesConfirmation);
  const dispatch = useDispatch();

  if (!data) return '';

  const feedback = data.map((study) => ({
    uuid: study.uuid,
    owned: t('study.upload.confirmation.info.owned', {
      name: study.title,
      id: study.studyInstanceUid,
    }),
    notOwned: t('study.upload.confirmation.info.not.owned', {
      name: study.title,
      id: study.studyInstanceUid,
    }),
  }));

  const clearAndCloseModal = () => {
    dispatch({ type: 'RESET_STUDY_UPLOAD_FILES_TO_CONFIRM' });
    close();
  };

  const updateStudy = () => {
    data.forEach((study) => {
      upload(study.studyInstanceUid);
      dispatch({ type: 'REMOVE_STUDY_UPLOAD_FILES_TO_CONFIRM', study });
    });
  };

  return (
    <Dialog
      className={styles.dialog}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => {}}
    >
      <Header title={t('study.confirm.upload')} />
      { data.map((study, index) => (study.haveInstance >= StudyUpload.status.existingAvailable) && (
        <div key={index.toString()}>
          <InnerHTML content={feedback && feedback.find((item) => item.uuid === study.uuid).owned} />
          { study.hospital && (
            <ul>
              <li>
                Hospital:&nbsp;
                <b>{study.hospital.name}</b>
              </li>
              <li>
                {`${t('study')}: `}
                <Link to={`/study/${study.uuid}`}>
                  <b>{study.uuid}</b>
                </Link>
              </li>
            </ul>
          )}
          { study.medicalCase && (
            <ul>
              <li>
                Hospital:&nbsp;
                <b>{study.medicalCase.patient.hospital.name}</b>
              </li>
              <li>
                {`${t('patient')}: `}
                <b>{`${study.medicalCase.patient.name} ${study.medicalCase.patient.surname}`}</b>
              </li>
              <li>
                {`${t('case')}: `}
                <b>
                  {study.medicalCase.title}
                </b>
              </li>
              <li>
                {`${t('study')}: `}
                <Link to={`/study/${study.uuid}`}>
                  <b>{study.uuid}</b>
                </Link>
              </li>
            </ul>
          )}
        </div>
      ))}
      { data.map((study, index) => (study.haveInstance === StudyUpload.status.existingNotAvailable) && (
        <InnerHTML key={index.toString()} content={feedback && feedback.find((item) => item.uuid === study.uuid).notOwned} />
      ))}
      <Typography className={styles.confirmation}>{t('study.upload.confirmation.message') }</Typography>
      <DialogActions className={styles.actions}>
        <Button
          variant="outlined"
          className={styles.button}
          onClick={clearAndCloseModal}
          color="primary"
        >
          {t('cancel')}
        </Button>
        <Button
          variant="contained"
          className={styles.button}
          onClick={updateStudy}
          color="primary"
        >
          {t('update.study')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
