import React from 'react';

import Layout from '../../components/MenuLayout';
import { UploadForm } from '../../components/Study/UploadForm';

export default ({ elementUuid }) => (
  <Layout>
    <UploadForm elementUuid={elementUuid} />
  </Layout>
);
