import React, { useRef, useState } from 'react';

import { navigate } from 'gatsby';
import { useQueryParam, BooleanParam } from 'use-query-params';
import { useTranslation } from 'react-i18next';
import { useKeycloak } from 'react-keycloak';
import { useMutation } from '@apollo/react-hooks';
import { ErrorMessage, Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';

import { alpha, makeStyles } from '@material-ui/core/styles';
import { Box, Button, Container, Grid, Paper, Typography } from '@material-ui/core';
import { Close, Cancel, Publish } from '@material-ui/icons';

import Loading from '../Common/Loading';
import { Form, Field, WarningText } from '../Common/styled/Form';
import { Study as DragAndDropStudy } from '../Common/DragAndDrop/Study';

import { CREATE_DICOM_STUDY } from '../../graphql/mutations';
import { isEmpty } from '../../utils/ObjectUtils';
import { UploadStudyConfirmationDialog, StudyUpload } from './UploadStudyConfirmationDialog';
import { ScrollableContainer } from '../Common/ScrollableContainer';
import { Navbar } from '../Navbar/styled/NavbarStyles';
import { SectionBar } from '../../componentsUI/SectionBar';
import { TextFieldUI } from '../../componentsUI/TextField';
import { TextAreaUI } from '../../componentsUI/TextArea';
import { SwitcherUI } from '../../componentsUI/SwitcherUI';
import { AlertUI, isKeycloakBackendError } from '../../componentsUI/Alert';
import { graphQLErrorParse } from '../../utils/ErrorGraphQLUtils';

const useStyles = makeStyles((theme) => ({
  flex: {
    display: 'flex',
    alignItems: 'stretch',
  },
  label: {
    color: alpha(theme.palette.text.primary, 0.54),
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    width: 'calc(100% - 40px)',
    maxWidth: 600,
    margin: '1em auto .5em',
    padding: '.5em 1.5em',
    '@media (max-width:600px)': {
      margin: '1em auto 0',
      padding: '.5em 1em',
    },
  },
  input: {
    paddingTop: '.5em',
    '& > .MuiTextField-root': {
      marginBottom: '.5em',
      width: '100%',
    },
    '& .MuiFormHelperText-root': {
      color: theme.palette.error.main,
    },
  },
  lower: {
    margin: 'auto 0 1em',
    paddingTop: 4,
  },
  errors: {
    '& > div': {
      margin: '5px 10px',
    },
    '& .MuiAlert-message': {
      width: 'calc(100% - 28px)',
    },
  },
  error: {
    fontSize: '.9375em',
    overflowWrap: 'break-word',
  },
  submit: {
    paddingTop: '.75em',
    display: 'flex',
  },
  button: {
    margin: '0 10px 0 auto',
    padding: '8px 25px',
    fontWeight: 700,
    border: `1px solid ${theme.palette.primary.main}30`,
  },
}));

export const UploadForm = ({ elementUuid }) => {
  const { t } = useTranslation();
  const [keycloak] = useKeycloak();
  const [serverError, setServerError] = useState(false);
  const [errorStyle, setErrorStyle] = useState({
    title: t('error'),
    severity: 'error',
  });
  const [fetchingBackend, setFetchingBackend] = useState(false);
  const [displayDragAndDrop, setDisplayDragAndDrop] = useState(true);
  const [fetchRequest, setFetchRequest] = useState(0);
  const [dicomStudies, setDicomStudies] = useState({ patients: [] });
  const [submittedForm, setSubmittedForm] = useState(false);
  const [clearForm, setClearForm] = useState(false);
  const [studiesFiles] = useState({});
  const [uploadingStudy] = useState({});
  const setDicomStudiesForParent = (dicomStudiesObj) => setDicomStudies({ ...dicomStudiesObj });
  const dispatch = useDispatch();
  const [uploadToHospital] = useQueryParam('uth', BooleanParam);
  const pageTitleIndex = uploadToHospital ? 'upload.hospital.study' : 'upload.case.study';
  const classes = useStyles();
  const formId = 'upload-study';
  const formSubmitRef = useRef();
  const formRef = useRef();

  const [confirmUpdateModal, setConfirmUpdateModal] = useState(false);
  const openConfirmUpdateModal = () => { setConfirmUpdateModal(true); };
  const closeConfirmUpdateModal = () => { setConfirmUpdateModal(false); };
  const [uploadConfirmationData, setUploadConfirmationData] = useState([]);

  let isValidFile = true;
  const setUploadedStatus = (state, formProps) => {
    if (state.status === 'ERROR') {
      isValidFile = false;
    } else if (state.status === 'UPLOADING') {
      isValidFile = true;
      formProps.setFieldValue('uploadedStudy', true);
    }
    formProps.setFieldTouched('uploadedStudy', true);
  };

  const closeForm = () => {
    closeConfirmUpdateModal();
    if (!uploadToHospital) navigate(`/case/${elementUuid}`);
    else navigate('/studies');
  };

  const uploadStudy = (studyInstanceUid) => {
    closeForm();
    dispatch({ type: 'ADD_UPLOADING_STUDY', study: uploadingStudy[studyInstanceUid] });
  };

  const resetError = () => {
    setErrorStyle({
      error: t('error'),
      severity: 'error',
    });
  };

  const submitDicomStudyForm = () => {
    if (formSubmitRef.current) formSubmitRef.current.click();
  };

  const refreshBackend = (msg) => {
    setFetchingBackend(true);
    fetch(`${process.env.GATSBY_CLOUD_API_BASE_URL}/command/cache:clear`, {
      headers: {
        Authorization: `Bearer ${keycloak.token}`,
      },
    })
      .then((r) => {
        resetError();
        setFetchingBackend(false);
        if (r.status === 200) {
          setServerError(false);
          submitDicomStudyForm();
          return;
        }
        setServerError(msg);
      })
      .catch((e) => {
        console.warn(e);
        setFetchingBackend(false);
        setServerError(msg);
      });
  };

  const processBackendError = (msg) => {
    if (isKeycloakBackendError(msg) && fetchRequest === 0) {
      setFetchRequest(fetchRequest + 1);
      setServerError(t('server.error.retrying.connection'));
      setErrorStyle({
        title: t('please.wait'),
        severity: 'info',
      });
      refreshBackend(msg);
      return;
    }
    setServerError(t('server.error'));
  };

  const [createDicomStudy, { loading: loadingUploadStudy }] = useMutation(CREATE_DICOM_STUDY, {
    onCompleted(data) {
      const study = data.createDicomStudy;
      if (study && study.haveInstance === StudyUpload.status.notExisting) {
        return uploadStudy(study.studyInstanceUid);
      }
      const update = uploadConfirmationData;
      update.push(study);
      dispatch({ type: 'ADD_STUDY_UPLOAD_FILES_TO_CONFIRM', study });
      setUploadConfirmationData(update);

      return openConfirmUpdateModal();
    },
    onError(e) {
      if (e.graphQLErrors && e.graphQLErrors.length) {
        const errorMessage = e.graphQLErrors[0].message;
        processBackendError(errorMessage);
        return;
      }
      if (e.message) {
        setServerError(graphQLErrorParse(e));
      }
    },
  });

  if (!displayDragAndDrop && clearForm) {
    setTimeout(() => {
      setDisplayDragAndDrop(true);
      setClearForm(false);
    }, 150);
  }

  const goBack = () => window.history.back();
  const resetForm = () => {
    setDisplayDragAndDrop(false);
    setClearForm(true);
    setDicomStudies({ patients: [] });
    formRef.current.state.values.uploadedStudy = false;
    formRef.current.setFieldError('uploadedStudy', 'Required field');
  };

  const getDisableResetStudyFile = () => {
    if (!formRef) return true;
    if (!formRef.current) return true;
    if (!formRef.current.state) return true;
    return formRef.current.state.values.uploadedStudy !== true;
  };

  const disableResetStudyFile = getDisableResetStudyFile();

  const backButton = { label: t('return'), icon: Close, onClick: goBack };

  const buttons = [
    { icon: Cancel, name: t('discard.study'), disabled: disableResetStudyFile, handleClick: resetForm },
    { name: 'divider2', type: 'divider' },
    { icon: Publish, form: formId, name: t('upload.study'), handleClick: submitDicomStudyForm },
  ];

  const validationSchema = Yup.object().shape({
    title: Yup.string().required(t('required.field')),
    description: Yup.string().required(t('required.field')),
    restricted: Yup.bool(),
    uploadedStudy: Yup.boolean()
      .test('uploadedStudy', t('invalid.dicom.file'), () => isValidFile)
      .required(t('required.field')),
  });

  const initialValues = {
    title: '',
    description: '',
    restricted: false,
    uploadedStudy: '',
  };

  const handleSubmit = async (values) => {
    setServerError(false);
    setUploadConfirmationData([]);
    dicomStudies.patients.forEach((patient) => {
      patient.studies.forEach(async (study) => {
        let totalInstances = 0;
        const modalities = new Set();
        study.series.forEach((serie) => {
          totalInstances += serie.instances.length;
          modalities.add(serie.modality);
        });
        const dicomInput = {
          title: values.title,
          description: values.description,
          restricted: values.restricted === 'true',
          medicalCaseUuid: !uploadToHospital ? elementUuid : '',
          hospitalUuid: uploadToHospital ? elementUuid : '',
          studyInstanceUid: study.studyInstanceUid,
          patientId: study.patientID,
          patientName: study.patientName,
          createdAt: study.createdAt,
          numberOfSeries: study.series.length,
          numberOfInstances: totalInstances,
          modalities: [...modalities],
          storageSize: study.storageSize,
        };
        studiesFiles[study.studyInstanceUid] = study.files;
        uploadingStudy[study.studyInstanceUid] = {
          id: Math.random().toString(36).substr(2, 10),
          studyInstanceUid: study.studyInstanceUid,
          description: study.studyDescription !== undefined ? study.studyDescription : 'undefined',
          files: study.files,
          filesCompleted: 0,
          nextFileIndex: 0,
        };
        try {
          await createDicomStudy({ variables: { dicomInput } });
        } catch (e) {
          if (e.message) {
            setServerError(e.message);
          }
        }
      });
    });
  };

  const FormErrorWarning = ({ errors }) => (
    <AlertUI title={t('warning')} severity="warning">
      {Object.keys(errors)
        .map((error, index) => (
          <Typography key={index.toString()} className={classes.error}>
            {`${errors[error]}: `}
            <strong>{t(error)}</strong>
          </Typography>
        ))}
    </AlertUI>
  );

  const ServerErrorAlert = () => (
    <AlertUI title={errorStyle.title} severity={errorStyle.severity}>
      <Typography className={classes.error}>{serverError}</Typography>
    </AlertUI>
  );

  const submitForm = (form) => {
    setSubmittedForm(true);
    form.handleSubmit();
  };

  return (
    <>
      <Navbar className={classes.subheader}>
        <SectionBar title={t(pageTitleIndex)} back={backButton} items={buttons} />
      </Navbar>
      <Container maxWidth="md">
        <ScrollableContainer padding="1em .75em">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnBlur
            onSubmit={handleSubmit}
            ref={formRef}
          >
            {(props) => (
              <Form autoComplete="off" id={formId} onSubmit={props.handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} className={classes.flex}>
                    <Paper elevation={2} className={classes.wrapper}>
                      <Box className={classes.input}>
                        <TextFieldUI name="title" label={t('title')} props={props} classes={classes} />
                        <TextAreaUI name="description" label={t('description')} props={props} classes={classes} />
                        {uploadToHospital && <SwitcherUI name="restricted" label={t('access.restricted')} props={props} />}
                      </Box>
                      <Box className={classes.lower}>
                        <Box className={classes.errors}>
                          {!!props.errors && !isEmpty(props.errors) && submittedForm
                            && (<FormErrorWarning errors={props.errors} />)}
                          {!!serverError && <ServerErrorAlert />}
                        </Box>
                        <Box className={classes.submit}>
                          <Button
                            variant="outlined"
                            className={classes.button}
                            disabled={loadingUploadStudy || disableResetStudyFile}
                            onClick={resetForm}
                            color="primary"
                          >
                            {t('delete')}
                          </Button>
                          <Button
                            variant="contained"
                            className={classes.button}
                            disabled={loadingUploadStudy || fetchingBackend || (submittedForm && !isEmpty(props.errors))}
                            onClick={() => submitForm(props)}
                            ref={formSubmitRef}
                            color="primary"
                          >
                            {(loadingUploadStudy || fetchingBackend) ? <Loading /> : t('upload.study')}
                          </Button>
                        </Box>
                      </Box>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {displayDragAndDrop && (
                      <DragAndDropStudy
                        setDicomStudiesForParent={setDicomStudiesForParent}
                        setFileUploadStateForParent={setUploadedStatus}
                        formProps={props}
                        clearForm={clearForm}
                        setClearForm={setClearForm}
                      />
                    )}
                    <Field name="uploadedStudy" className="hide" />
                    <ErrorMessage name="uploadedStudy" component={WarningText} />
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
          {confirmUpdateModal && (
            <UploadStudyConfirmationDialog
              open={confirmUpdateModal}
              data={uploadConfirmationData}
              close={closeForm}
              upload={uploadStudy}
            />
          )}
        </ScrollableContainer>
      </Container>
    </>
  );
};
